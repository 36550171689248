import axios from "axios";

axios.defaults.baseURL = //"https://uni.teslaexch.com";
  localStorage.getItem("baseUrl") || process.env.VUE_APP_BASEURL;

import router from "@/router/router";

export default {
  init() {
    //
    axios.interceptors.request.use(
      (config) => {
        if (
          config.url != "Odds/GetUserOdds" &&
          config.url != "Event/TreeSportWithEvent" &&
          config.url != "Bet/GetUserExpectedProfitLoss" &&
          config.url != "Odds/GetUserOddsV2" &&
          config.url != "Bet/GetUserOpenBets" &&
          config.url != "Bet/PlaceBetV2" &&
          config.url != "Odds/GetUserLimits"
        )
          document.getElementById("loading").style.display = "block";

        config.baseURL = //"https://uni.teslaexch.com";
          localStorage.getItem("baseUrl") || process.env.VUE_APP_BASEURL;

        if (localStorage.getItem("accessToken")) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken");
        }

        return config;
      },
      (error) => {
        document.getElementById("loading").style.display = "none";
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      function (response) {
        // alert(JSON.stringify(response))
        document.getElementById("loading").style.display = "none";
        //const { config } = response;
        //const originalRequest = config;

        if (response && response.data.status == 401) {
          try {
            if (localStorage.getItem("accessToken")) {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("expire_in");
              localStorage.removeItem("userinfo");
            }
          } catch (e) {
            console.error(e);
          }
          router.push("/Auth/Logout");
        }

        if (response.data.status == 500) {
          /*if (!isAlreadyFetchingAccessToken) {
                      isAlreadyFetchingAccessToken = true;
                      store
                        .dispatch("authModule/fetchAccessToken")
                        .then((access_token) => {
                          isAlreadyFetchingAccessToken = false;

                          if (response.data.success == true) {
                            onAccessTokenFetched(access_token.data.result.accessToken);
                          }
                          //onAccessTokenFetched(access_token);
                        });
                    }

                    const retryOriginalRequest = new Promise((resolve) => {
                      addSubscriber((access_token) => {
                        store.commit("mainModule/SET_BEARER", access_token);
                        originalRequest.headers.Authorization = "Bearer " + access_token;

                        resolve(axios(originalRequest));
                      });
                    });

                    return retryOriginalRequest;*/
        }

        return response;
      },

      function (error) {
        document.getElementById("loading").style.display = "none";
        // const { config, response: { status } } = error
        const { /*config,*/ response } = error;
        //const originalRequest = config;

        if (response && response.status == 401) {
          router.push("/Auth/Logout");
        }

        if (response && response.status == 500) {
          if (!response.data?.error) {
            response.data = {
              error: {
                message:
                  response.data ||
                  response.statusText ||
                  "Internal Server Error",
              },
            };
          }
        }

        /*if (response && (response.status == 401 || response.status == 500)) {
                  if (!isAlreadyFetchingAccessToken) {
                    isAlreadyFetchingAccessToken = true;
                    store
                      .dispatch("authModule/fetchAccessToken")
                      .then((access_token) => {
                        isAlreadyFetchingAccessToken = false;
                        onAccessTokenFetched(access_token);
                      });
                  }

                  const retryOriginalRequest = new Promise((resolve) => {
                    addSubscriber((access_token) => {
                      originalRequest.headers.Authorization = "Bearer " + access_token;

                      resolve(axios(originalRequest));
                    });
                  });
                  return retryOriginalRequest;
                }*/

        //console.log("0:", JSON.stringify(response));
        //console.log("00:", JSON.stringify(error));

        if (String(error).includes("Network Error")) {
          return Promise.reject(
            new Error("Please Check Your Network Connection...")
          );
        }

        if (response && !response.data.error) {
          if (response.data == "") {
            response.data = {};
          }
          response.data["error"] = { message: response.data.result };
        }

        return Promise.reject(response);
      }
    );
  },

  //getBetSlipData() {
  //  return axios.post("/api/Settings/GetBetSlipByUserId");
  //},

  setUserBetSlip(data) {
    return axios.post("UserStake/Add", data);
  },

  GetBySportName(data) {
    return axios.get("event/GetBySportName", { params: data });
  },

  GetSportWithEventTree(data) {
    let sportWithEventTreeApi = "";
    if (location.hostname.includes("test"))
      sportWithEventTreeApi = process.env.VUE_APP_BASEURL_FOR_TEST_DOMAIN;
    else sportWithEventTreeApi = process.env.VUE_APP_BASEURL;
    // sportWithEventTreeApi = "https://uni.teslaexch.com";
    return axios.get(sportWithEventTreeApi + "Event/TreeSportWithEvent", {
      params: data,
    });
  },

  GetByEventId(data) {
    return axios.get("Market/GetByEventId", { params: data });
  },

  GetUserOdds(data) {
    return axios.get("Odds/GetUserOddsV2", { params: data });
  },

  Closest(data) {
    return axios.get("Event/Closest", { params: data });
  },

  PlaceBet(data) {
    return axios.post("Bet/PlaceBetV2", data);
  },

  GetUserExpectedProfitLoss(data) {
    return axios.get("Bet/GetUserExpectedProfitLoss", { params: data });
  },

  GetUserOpenBets(data) {
    return axios.get("Bet/GetUserOpenBets", { params: data });
  },

  GetScoreCard(data) {
    return axios.get("api/services/app/Sport/GetScoreCard", { params: data });
  },

  GetUserBetHistory(data) {
    //return axios.get("api/services/app/Bet/GetUserBetHistory", {
    //return axios.get("api/services/app/Bet/GetUserBetHistory", {
    //  params: data,
    //});

    //api/services/app/Bet/GetUserBetHistory
    return axios.post("/Bet/GetUserBetHistory", {
      params: data,
    });
  },

  GetTransactionHistory(data) {
    //api/services/app/Transaction/GetTransactionHistory
    return axios.get("Transaction/GetHistory", {
      params: data,
    });
  },

  GetBetTransactionHistoryDetail(data) {
    return axios.get("Transaction/GetDetail", {
      params: data,
    });
  },

  GetSetting(data) {
    return axios.get("Setting/GetSetting", {
      params: data,
    });
  },

  StoreImage(data) {
    return axios.post("Setting/StoreImage", data);
  },

  CreateRequestForDepositWithdrawal(data) {
    return axios.post(
      "DepositWithdrawal/CreateRequestForDepositWithdrawal",
      data
    );
  },
  CreateRequestForDepositUpi(data) {
    return axios.post("api/Payment/Generate", data);
  },
  CheckRequestForDepositUpi(data) {
    return axios.post("api/Payment/Check", data);
  },

  GetGames(data) {
    return axios.get("api/InternationalCasino/GetGames", {
      params: data,
    });
  },

  DreamCasinoGameUrl(data) {
    let url = "/api/DreamCasino/games/url";
    if (
        !location.hostname.includes("test") &&
        !location.host.includes("localhost") &&
        !location.hostname.includes("pages.dev")
    )
      url = "https://dreamcasino.strikexch9.com" + url;
    else
      url = "https://intl.teslaexch.com" + url;

    return axios.post(url, data);
  },
  KingCasinoGameUrl() {
    let url = "/api/token";
    if (
        !location.hostname.includes("test") &&
        !location.host.includes("localhost") &&
        !location.hostname.includes("pages.dev")
    )
      url = "https://king.strikexch9.com" + url;
    else
      url = "https://king.teslaexch.com" + url;
    return axios.post(url);
  },
  MacCasinoGameUrl(data) {
    let url = "/login";
    if (
        !location.hostname.includes("test") &&
        !location.host.includes("localhost") &&
        !location.hostname.includes("pages.dev")
    )
      url = "https://mac.strikexch9.com" + url;
    else
      url = "https://mactest.teslaexch.com" + url;

    return axios.post(url, data);
  },
  InternationalGame(data) {
    let url = `https://test-tesla-cdn.b-cdn.net/games/intls/${data.name}.json`;
    return fetch(url, {
      cache: "no-cache",
      headers: {
        crossdomain: true,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      },
    });
  },

  UniversalCasinoTokens(data) {
    return axios.post("api/launch", data);
  },

  PlayerRequests(data) {
    return axios.post("DepositWithdrawal/PlayerRequests", data);
  },

  GetGrid(data) {
    return axios.post("DepositWithdrawal/GetDepositWithdrawals", data);
  },

  GetUserLimits(data) {
    return axios.get("Odds/GetUserLimits", { params: data });
  },

  ChangePassword(data) {
    return axios.post("Account/changePassword", data);
  },

  GetIp() {
    return axios.get("https://ip.teslaexch.com/ip/get");
  },

  GetBonuses(data) {
    return axios.get("Bonus/GetBonuses", { params: data });
  },

  Claim(data) {
    return axios.get("Bonus/Claim", { params: data });
  },

  GetPaymentInfo() {
    return axios.get("Account/GetPaymentInfo");
  },

  CreateWithdrawal(data) {
    return axios.post("DepositWithdrawal/CreateWithdrawal", data);
  },

  CreateDeposit(data) {
    return axios.post("DepositWithdrawal/CreateDeposit", data);
  },

  GetUserWithdrawalHistory(data) {
    return axios.get("DepositWithdrawal/GetUserWithdrawalHistory", {
      params: data,
    });
  },
};
