/**
 * /* eslint-disable no-undef
 *
 * @format
 */

import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);

// router
import router from "./router/router";

// bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Styles
import "./assets/styles.scss";

// Axios
// eslint-disable-next-line no-unused-vars
import axios from "./axios.js";

// API Calls
import "./http/request";

// Store
import store from "./store/store";

// Notification
import Toast from "vue-toastification";
// Import the CSS Notification
import "vue-toastification/dist/index.css";
const ToastOptions = {};

// Mixin Toast
import mixinToast from "./mixins/mixinToast.js";
app.mixin(mixinToast);

import mixinGeneral from "./mixins/mixinGeneral";
app.mixin(mixinGeneral);
// Notification
//import Notifications from "@kyvg/vue3-notification";

import VueClickAway from "vue3-click-away";

// marquee text
import Vue3Marquee from "vue3-marquee";

// hub
//import EventsHub from "./event-hub";
import "./eventsHub2";
// Our plugins
//import FloatingMenuButton from "floating-menu";
//import "floating-menu/dist/style.css";

//app.use(FloatingMenuButton);
// fontawsome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faExchangeAlt,
  faPlus,
  faMinus,
  faArrowDown,
  faArrowUp,
  faArrowAltCircleDown,
  faCaretDown,
  faCaretUp,
  faAngleDown,
  faAngleUp,
  faClock,
  faFootballBall,
  faTableTennis,
  faAngleRight,
  faSearch,
  faSpinner,
  faCalendar,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import mitt from "mitt";
library.add(
  faPhone,
  faExchangeAlt,
  faPlus,
  faMinus,
  faArrowDown,
  faArrowUp,
  faArrowAltCircleDown,
  faCaretDown,
  faCaretUp,
  faAngleDown,
  faAngleUp,
  faClock,
  faFootballBall,
  faTableTennis,
  faAngleRight,
  faSearch,
  faSpinner,
  faCalendar,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faArrowRight,
  faCheck
);

//
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
//

import DisableAutocomplete from "vue-disable-autocomplete";

import Toaster from "@meforma/vue-toaster";

app
  .use(store)
  .use(router)
  .use(Toast, ToastOptions)
  //.use(Notifications)
  .use(VueClickAway)
  .use(DisableAutocomplete)
  .use(Vue3Marquee)
  .use(Toaster)
  .component("font-awesome-icon", FontAwesomeIcon)
  //.use(EventsHub)
  .mount("#app");

/*createApp(App)
  .use(router)
  .mount("#app");*/
