<template>
  <div class="loading-body-full" id="loadingbox">
    <div class="dl">
      <div
        data-v-6ae436b8=""
        class="vue-loading"
        style="width: 65px;height: 65px;"
        :style="{ fill: color }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 50 50"
          style="enable-background:new 0 0 50 50;"
          xml:space="preserve"
        >
          <path
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"
            ></animateTransform>
          </path>
        </svg>
      </div>
    </div>
  </div>
  <!-- <div
    class="con-vs-loading vs-loading-background-undefined vs-loading-color-undefined"
  >

    <div class="vs-loading default" style="transform: scale(0.6);">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
      <img src="" />

    </div>
  </div> -->
</template>

<script>
export default {
  components: {},
  data() {
    return {
      color: "#e3c16a",
      timerInterval: null,
    };
  },
  methods: {
    /*startTimer() {
      this.timerInterval = setInterval(() => {
        this.color = "";

        var letters = "0123456789abcdef";
        this.color += "#";
        for (var i = 0; i < 6; i++) {
          this.color += letters[Math.floor(Math.random() * 16)];
        }

        
      }, 1000);
    },*/
  },
  mounted() {
    //this.startTimer();
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

.loading-body-full {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background-color: #b1b1b163;
  position: absolute;
}

.dl {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  background: #8585855e;
  border-radius: 50%;
}

/*** */
/* .vs-con-loading__container .con-vs-loading {
  position: absolute !important;
  width: 100%;
  height: 100%;
  min-height: 60px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.con-vs-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: hsla(0, 0%, 100%, 0.6);
  z-index: 300000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.con-vs-loading .vs-loading {
  position: relative;
  width: 55px;
  height: 55px;
  display: block;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.con-vs-loading .vs-loading.border .effect-1,
.con-vs-loading .vs-loading.border .effect-2,
.con-vs-loading .vs-loading.default .effect-1,
.con-vs-loading .vs-loading.default .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(var(--vs-primary), 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.con-vs-loading .vs-loading.border .effect-1,
.con-vs-loading .vs-loading.default .effect-1 {
  -webkit-animation: rotate 1s ease infinite;
  animation: rotate 1s ease infinite;
}
.con-vs-loading .vs-loading .effects {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.con-vs-loading .vs-loading.border .effect-2,
.con-vs-loading .vs-loading.default .effect-2 {
  -webkit-animation: rotateOpacity 1s ease infinite 0.1s;
  animation: rotateOpacity 1s ease infinite 0.1s;
} */
</style>
