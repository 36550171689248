/** @format */

import {
  createRouter,
  /*createWebHashHistory,*/
  createWebHistory,
} from "vue-router";

//import { DOMAIN_TITLE } from "../.env";
import store from "../store/store";

const routes = [
  {
    path: "",
    component: () => import("@/layouts/main/Main.vue"),
    meta: {
      /*requiresAuth: true*/
    },
    children: [
      {
        path: "",
        redirect: "/home",
      },

      {
        path: "/home",
        name: "home",
        component: () => import("@/views/pages/Home.vue"),
        meta: { title: "Home" },
      },

      {
        path: "/inplay/:id?",
        name: "inplay",
        component: () => import("@/views/pages/Inplay.vue"),
        meta: { title: "Inplay" },
      },

      {
        path: "/markets/:eventid?/:dreamid?",
        name: "Markets",
        component: () => import("@/views/pages/markets/Markets.vue"),
        // beforeEnter: checkAuth,
        meta: {
          //requiresAuth: true,
          title: "Markets",
        },
      },

      {
        path: "/matchlist/:id",
        name: "matchlist",
        component: () => import("@/views/pages/Match-list.vue"),
        meta: {
          title: "Match List",
        },
      },
      {
        path: "/casino",
        name: "casino",
        component: () => import("@/views/pages/casino/Casino.vue"),
        meta: {
          title: "Casino",
        },
      },
      {
        path: "/underConstruction",
        name: "UnderConstruction",
        component: () =>
          import("@/views/pages/under-construction/UnderConstruction.vue"),
        meta: {
          title: "Under Construction",
        },
      },
      {
        path: "/lobby",
        name: "lobby",
        component: () => import("@/views/pages/lotus/Lotus.vue"),
        meta: {
          requiresAuth: true,
          title: "Lotus",
        },
      },
      {
        path: "/lobby-page/:section",
        name: "lobby-page",
        component: () => import("@/views/pages/lotus/Lobby.vue"),
        meta: {
          requiresAuth: true,
          title: "Lobby",
        },
      },
      //
      {
        path: "/change-password",
        name: "change-password",
        component: () => import("@/views/pages/account/ChangePassword.vue"),
        meta: {
          title: "Change Password",
        },
      },
      {
        path: "/account-cash-managemnt",
        name: "account-cash-managemnt",
        component: () =>
          import("@/views/pages/account/AccountCashManagemnt.vue"),
        meta: {
          title: "Account Cash Managemnt",
        },
      },
      {
        path: "/account-cash-statement",
        name: "account-cash-statement",
        component: () =>
          import("@/views/pages/account/AccountCashStatement.vue"),
        meta: {
          title: "Account Cash Statement",
        },
      },
      {
        path: "/bet-history",
        name: "bet-history",
        component: () => import("@/views/pages/account/BetHistory.vue"),
        meta: {
          title: "Bet History",
        },
      },
      {
        path: "/history/:section?",
        name: "history",
        component: () =>
          import("@/views/pages/account/components/newHistory.vue"),
        meta: { title: "History" },
      },
      {
        path: "/ucurrent-bet",
        name: "ucurrent-bet",
        component: () => import("@/views/pages/account/UcurrentBet.vue"),
        meta: {
          title: "Ucurrent Bet",
        },
      },
      {
        path: "/ProfitLoss",
        name: "profit-loss",
        component: () => import("@/views/pages/account/ProfitLoss.vue"),
        meta: {
          title: "Profit Loss",
        },
      },
      {
        path: "/dwrequests",
        name: "dw-requests",
        component: () => import("@/views/pages/dw-requests/DWRequests.vue"),
        meta: {
          title: "DW Requests",
        },
      },
      {
        path: "/depositw/:id?",
        name: "depositw",
        component: () => import("@/views/pages/depositw/DepositW.vue"),
        meta: {
          title: "Deposit Withdrawal",
        },
      },
      {
        path: "/lottery",
        name: "lottery",
        component: () => import("@/views/pages/Lottery.vue"),
        meta: { title: "Lottery" },
      },
      {
        path: "/lottery/market/:id?",
        name: "lottery-market",
        component: () => import("@/views/pages/LotteryMarket.vue"),
        meta: { title: "Lottery Market" },
      },

      {
        path: "/bonus",
        name: "bonus",
        component: () => import("@/views/pages/account/Bonus.vue"),
        meta: {
          requiresAuth: true,
          title: "Bonus",
        },
      },
    ],
  },

  {
    path: "/auth",
    component: () => import("@/layouts/auth/AuthLayout.vue"),
    meta: {},
    children: [
      {
        path: "/",
        redirect: "/auth/login",
      },

      {
        path: "/auth/login",
        name: "login",
        component: () => import("@/views/auth/Login.vue"),
        meta: { title: "Login" },
      },
      {
        path: "/auth/signup",
        name: "signup",
        component: () => import("@/views/auth/SignUp.vue"),
        meta: { title: "Signup" },
      },
      {
        path: "/auth/verfication",
        name: "verfication",
        component: () => import("@/views/auth/Verfication.vue"),
        meta: { title: "Verfication" },
      },
      {
        path: "/auth/logout",
        redirect: "/auth/login",
      },
    ],
  },

  //{
  //  path: "/",
  //  name: "home",
  //  component: Home,
  //  meta: {
  //    layout: "ttt",
  //  },
  //},
  {
    path: "",
    component: () => import("@/layouts/full-page/FullPageLayout.vue"),
    children: [
      {
        path: "/not-authorized",
        name: "not-authorized",
        component: () =>
          import("@/views/pages/not-authorized/NotAuthorized.vue"),
        meta: { title: "Not Authorized" },
      },
      {
        path: "/error-404",
        name: "not-found-404",
        component: () => import("@/views/pages/not-founds/Error-404.vue"),
        meta: { title: "Page Not Found" },
      },
      {
        path: "/error-500",
        name: "not-found-500",
        component: () => import("@/views/pages/not-founds/Error-500.vue"),
        meta: { title: "Server Error 500" },
      },
      {
        path: "/under-maintenance",
        name: "under-maintenance",
        component: () =>
          import("@/views/pages/not-founds/Under-Maintenance.vue"),
        meta: { title: "Under Maintenance" },
      },
      {
        path: "/test",
        name: "test",
        component: () => import("@/views/pages/not-founds/Test.vue"),
        meta: { title: "nnnServer Error 500" },
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/error-404",
    meta: { title: "Not Found!" },
  },
];

const router = createRouter({
  history: /*createWebHashHistory*/ createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  /*document.title = to.meta.title
      ? `${DOMAIN_TITLE} | ` + to.meta?.title
      : `${DOMAIN_TITLE}`;*/

  if (to.name == "lobby") {
    store.dispatch("setSliderShow", false);
    //store.dispatch("setImageSliderHeader", false);
    store.dispatch("setNewsBoxMarquee", false);
    store.dispatch("setNavbarScrollabel", false);
    store.dispatch("setNavBackToSports", true);
  }

  if (from.name == "lobby") {
    store.dispatch("setSliderShow", true);
    //store.dispatch("setImageSliderHeader", true);
    store.dispatch("setNewsBoxMarquee", true);
    store.dispatch("setNavbarScrollabel", true);
    store.dispatch("setNavBackToSports", false);
    store.dispatch("moduleMain/getUserExpectedProfitLoss");
    store.dispatch("moduleMain/getUserOpenBets");
    store.dispatch("moduleMain/getUserExpectedProfitLoss");
  }

  store.dispatch("setNavbarScrollabel", true);

  //--------------------------------------------------

  // if siteSuspended is true
  //localStorage.setItem("siteSuspended", true);
  if (
    localStorage.getItem("siteSuspended") == "true" &&
    to.name != "under-maintenance"
  ) {
    store
      .dispatch("configModule/getDomainSettingTreeSport")
      .then(() => {})
      .catch(() => {});

    next({
      path: "/under-maintenance",
    });
  }

  //--------------------------------------------------

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //if (to.name == "home") store.dispatch("setImageSliderHeader", true);
    //if (from.name == "home") store.dispatch("setImageSliderHeader", false);
    //
    //if (to.name == "inplay") store.dispatch("setImageSliderHeader", true);
    //if (from.name == "inplay") store.dispatch("setImageSliderHeader", false);
    //
    //if (to.name == "matchlist") store.dispatch("setImageSliderHeader", true);
    //if (from.name == "matchlist") store.dispatch("setImageSliderHeader", false);

    //
    if (localStorage.getItem("accessToken")) {
      next();
      return;
    }
    next({
      path: "/auth/login",
      query: {
        to: to.path,
      },
    });

    //router.push({ path: "/auth/login", query: { to: to.path } });
  }

  store.dispatch("authModule/updateInReload").then().catch();

  next();
});

router.afterEach(() => {
  const appLoading = document.getElementById("loading");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
