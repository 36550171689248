export default {
  data() {
    return {
      FOOTBALL_SPORT: 1,
      TENNIS_SPORT: 2,
      CRICKET_SPORT: 3,
      HORSE_RACING_SPORT: 26,
      KABBADI_SPORT: 25,
      _BALANCE: this.$store.getters["moduleMain/getOpenBets"]?.balance || 0,
      _EXPOSURE: this.$store.getters["moduleMain/getOpenBets"]?.exposure || 0,
    };
  },
  methods: {
    formatDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  _sportIsActive(sportId) {
    // If Casino Or Events Of Sports List is Empty ==> Remove Links it from Menus
    // Else If User Is Loggined Get Access to Casino/Sport/Events from User Info after
    // Else Get Access to Casino/Sport/Events from DomainSetting Json file
    //---------------------------------- for casino --------------------------------------//
    let casino_is_empty = false;
    if (localStorage.getItem("casino_is_empty"))
      casino_is_empty =
        localStorage.getItem("casino_is_empty") == "true" ? true : false;
    //---------------------------------- for Sports --------------------------------------//
    let tennis_empty = true;
    let football_empty = true;
    let cricket_empty = true;
    let horse_empty = true;
    let kabaddi_empty = true;

    if (this.$store.getters["moduleMain/getEventsBySportId"](this.TENNIS_SPORT))
      tennis_empty = false;
    if (
      this.$store.getters["moduleMain/getEventsBySportId"](this.FOOTBALL_SPORT)
    )
      football_empty = false;
    if (
      this.$store.getters["moduleMain/getEventsBySportId"](this.CRICKET_SPORT)
    )
      cricket_empty = false;
    if (
      this.$store.getters["moduleMain/getEventsBySportId"](
        this.HORSE_RACING_SPORT
      )
    )
      horse_empty = false;
    if (
      this.$store.getters["moduleMain/getEventsBySportId"](this.KABADDI_SPORT)
    )
      kabaddi_empty = false;
    //---------------------------------- IN USER LOGGINED ----------------------------------//

    if (localStorage.getItem("userinfo")) {
      let userdata = JSON.parse(localStorage.getItem("userinfo"));

      switch (sportId) {
        case this.CASINO_SPORT:
          return !casino_is_empty; //| true; //userdata.casino;

        case this.TENNIS_SPORT:
          return tennis_empty == true ? !tennis_empty : userdata.tennis;

        case this.FOOTBALL_SPORT:
          return football_empty == true ? !football_empty : userdata.football;

        case this.CRICKET_SPORT:
          return cricket_empty == true ? !cricket_empty : userdata.cricket;

        case this.HORSE_RACING_SPORT:
          return horse_empty == true
            ? !horse_empty
            : this.$store.getters["configModule/domainSettings"]?.horseRacing;

        case this.KABADDI_SPORT:
          return kabaddi_empty == true
            ? !kabaddi_empty
            : this.$store.getters["configModule/domainSettings"]?.kabaddi;

        default:
          return false;
      }
    }
    //-------------------------------- IN USER NOT LOGGINED --------------------------------//
    else {
      switch (sportId) {
        case this.CASINO_SPORT:
          let gamesData = [];
          if (window.innerWidth < 769) {
            gamesData =
              localStorage.getItem("mobileGames") != undefined
                ? JSON.parse(localStorage.getItem("mobileGames"))
                : [];
          } else {
            gamesData =
              localStorage.getItem("desktopGames") != undefined
                ? JSON.parse(localStorage.getItem("desktopGames"))
                : [];
          }

          let casinoStatus;
          if (
            gamesData && gamesData.length > 0
              ? (casinoStatus =
                  this.$store.getters["configModule/domainSettings"]?.casino)
              : (casinoStatus = false)
          );

          return casinoStatus /*
            this.$store.getters["configModule/domainSettings"]?.casino*/;

        case this.TENNIS_SPORT:
          return tennis_empty == true
            ? !tennis_empty
            : this.$store.getters["configModule/domainSettings"]?.tennis;

        case this.FOOTBALL_SPORT:
          return football_empty == true
            ? !football_empty
            : this.$store.getters["configModule/domainSettings"]?.football;

        case this.CRICKET_SPORT:
          return cricket_empty == true
            ? !cricket_empty
            : this.$store.getters["configModule/domainSettings"]?.cricket;

        case this.HORSE_RACING_SPORT:
          return horse_empty == true
            ? !horse_empty
            : this.$store.getters["configModule/domainSettings"]?.horseRacing;

        case this.KABADDI_SPORT:
          return kabaddi_empty == true
            ? !kabaddi_empty
            : this.$store.getters["configModule/domainSettings"]?.kabaddi;

        default:
          return false;
      }
    }
  },

  computed: {
    footballIsActive() {
      if (this.$store.getters["authModule/userinfo"]) {
        if (
          this.$store.getters["authModule/userinfo"]?.football &&
          this.$store.getters["configModule/domainSettings"]?.football
        )
          return true;
        else return false;
      } else
        return this.$store.getters["configModule/domainSettings"]?.football;
    },
    tennisIsActive() {
      if (this.$store.getters["authModule/userinfo"]) {
        if (
          this.$store.getters["authModule/userinfo"]?.tennis &&
          this.$store.getters["configModule/domainSettings"]?.tennis
        )
          return true;
        else return false;
      } else return this.$store.getters["configModule/domainSettings"]?.tennis;
    },
    cricketIsActive() {
      if (this.$store.getters["authModule/userinfo"]) {
        if (
          this.$store.getters["authModule/userinfo"]?.cricket &&
          this.$store.getters["configModule/domainSettings"]?.cricket
        )
          return true;
        else return false;
      } else return this.$store.getters["configModule/domainSettings"]?.cricket;
    },
    kabaddiIsActive() {
      if (this.$store.getters["authModule/userinfo"]) {
        if (
          this.$store.getters["authModule/userinfo"]?.kabaddi &&
          this.$store.getters["configModule/domainSettings"]?.kabaddi
        )
          return true;
        else return false;
      } else return this.$store.getters["configModule/domainSettings"]?.kabaddi;
    },
    horseRacingIsActive() {
      if (this.$store.getters["authModule/userinfo"]) {
        if (
          this.$store.getters["authModule/userinfo"]?.horseRacing &&
          this.$store.getters["configModule/domainSettings"]?.horseRacing
        )
          return true;
        else return false;
      } else
        return this.$store.getters["configModule/domainSettings"]?.horseRacing;
    },
  },
};
