// axios
import axios from "axios";

const domain = "";
const baseURL = "http://5.5.5.5:5555";

export default axios.create({
  baseURL: baseURL,
  domain,
});
