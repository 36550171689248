import axios from "axios";

axios.defaults.baseURL = //"https://uni.teslaexch.com";
   localStorage.getItem("baseUrl") || process.env.VUE_APP_BASEURL;

//

//import router from "@/router/router";

export default {
  init() {
    /*axios.interceptors.request.use(
      (config) => {
        //delete axios.defaults.headers.common["Authorization"];
        //delete config.headers.common["Authorization"];

        config.baseURL =
          localStorage.getItem("baseUrl") || process.env.VUE_APP_BASEURL;

        if (localStorage.getItem("accessToken")) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken");
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function(response) {
        document.getElementById("loading").style.display = "none";

        if (response.status === 401) {
          router.push("/auth/logout");
        }

        if (localStorage.getItem("accessToken")) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken");
        }

        return response;
      },
      function(error) {
        document.getElementById("loading").style.display = "none";

        const { response } = error;

        if (response.status === 401) {
          router.push("/auth/logout");
        }

        if (response && response.status == 500) {
          if (!response.data?.error) {
            response.data = {
              error: {
                message:
                  response.data ||
                  response.statusText ||
                  "Internal Server Error",
              },
            };
          }
        }

        if (String(error).includes("Network Error")) {
          return Promise.reject(
            new Error("Please Check Your Network Connection...")
          );
        }

        if (!response.data.error)
          response.data["error"] = { message: response.data.result };

        return Promise.reject(response);
      }
    );*/
  },

  login(data) {
    return axios.post("Account/Authenticate", data, {
      headers: {
        Authorization: null,
      },
    });
  },

  loginAsDemoUser(data) {
    return axios.get("Account/LoginAsDemoUser", { params: data });
  },

  newToken(token) {
    return axios.post("/api/v1/token/token", {
      client_user: "pppanel_user",
      client_secret: "hkMg6ldsffevahredigokd%%dfg##ghfgh&&fd",
      redirect_uri: "home",
      grant_type: "authorization_code",
      code: token,
    });
  },

  register(data) {
    return axios.post("Account/Register", data, {
      headers: {
        Authorization: null,
      },
    });
  },

  verificationUser(data) {
    return axios.post("Account/VerificationUser", data, {
      headers: {
        Authorization: null,
      },
    });
  },

  resendVerificationCode(data) {
    return axios.post("Account/ResendVerificationCode", data, {
      headers: {
        Authorization: null,
      },
    });
  },

  /*GetDomainSetting() {
    let domainSettingApi = "";
    if (location.hostname.includes("test"))
      domainSettingApi = process.env.VUE_APP_BASEURL_FOR_TEST_DOMAIN;
    else domainSettingApi = process.env.VUE_APP_BASEURL;

    return axios.get(domainSettingApi + "api/Setting/GetDomainSetting", {
      headers: {
        Authorization: null,
      },
    });

    // return axios.get(
    //   process.env.VUE_APP_BASEURL + "api/Setting/GetDomainSetting",
    //   {
    //     headers: {
    //       Authorization: null,
    //     },
    //   }
    // );
  },*/

  randomNo(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },

  async GetDomainSettingTreeSport() {
    let siteBaseDataJson;
    let url;
    let randomKey = this.randomNo(100, 1000);

    if (
      location.hostname.includes("test") ||
      location.host.includes("localhost") ||
      location.hostname.includes("pages.dev")
    ) {
      // For test  and local website
      // url = `https://tesla-cdn.b-cdn.net/lotus444.com.json?key=${randomKey}`;
      //url = `https://tesla-cdn.b-cdn.net/teslaexch.com.json?key=${randomKey}`;
      url = `https://test-tesla-cdn.b-cdn.net/test.teslaexch.com.json?key=${randomKey}`;
    } else {
      url = `https://tesla-cdn.b-cdn.net/${location.hostname}.json?key=${randomKey}`;
    }

    try {
      siteBaseDataJson = await fetch(url, {
        cache: "no-cache",
        headers: {
          //Authorization: null,
          crossdomain: true,
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          }
          return response.json();
        })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          throw new Error("HTTP error ..");
        });

      /*delete axios.defaults.headers.common["Authorization"];
      siteBaseDataJson = await axios.get(url, {
        headers: {
          crossdomain: true,
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      });

      if (!siteBaseDataJson || siteBaseDataJson.status != 200) {
        siteBaseDataJson = await this.GetDomainSettingTreeSport();
      }*/

      const token = localStorage.getItem("accessToken");
      if (token) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken");
      }
    } catch {
      siteBaseDataJson = await this.GetDomainSettingTreeSport();
    } finally {
      const token = localStorage.getItem("accessToken");
      if (token) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken");
      }
    }

    return siteBaseDataJson;
  },

  async GetDomainSettingTreeSportSecondryAPI() {
    let siteBaseDataJson;
    let url = "https://r.teslaexch.com//DefaultDomain.json";

    siteBaseDataJson = await axios.get(url, {
      headers: {
        crossdomain: true,
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });

    return siteBaseDataJson;
  },

  GetAccessTokenValidateTime(data) {
    return axios.post("Account/GetAccessTokenValidateTime", null, {
      params: data,
      headers: { Authorization: null },
    });
  },

  RefreshToken(data) {
    return axios.get("Account/RefreshToken", {
      params: data,
      headers: { Authorization: null },
    });
  },

  GetSettingExpiry(data) {
    return axios.get("api/setting/GetSettingExpiry", {
      params: data,
      headers: { Authorization: null },
    });
  },
};
