import axiosMain from "../../http/request/main/index";
import authAxios from "../../http/request/auth/index";
import axios from "axios";

const actions = {
  setUserBetSlip({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosMain
        .setUserBetSlip(payload)
        .then((response) => {
          if (response.data.success == true) {
            commit("SET_BETSLIP_DATA", payload);

            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDomainSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      authAxios
        .GetDomainSetting(payload)
        .then((response) => {
          if (response.data.success == true) {
            commit("SET_DOMAIN_SETTINGS", response.data.result);

            if (response.data.result.baseUrl)
              localStorage.setItem("baseUrl", response.data.result?.baseUrl);
              // localStorage.setItem("baseUrl", "https://uni.teslaexch.com");

            //localStorage.setItem("baseUrl", "https://api.strikexch9.com/");
            //localStorage.setItem("baseUrl", "https://testapi.teslaexch.com/");

            localStorage.setItem(
              "domainSetting",
              JSON.stringify(response.data.result)
            );

            localStorage.setItem(
              "siteSuspended",
              response.data.result.siteSuspended
            );

            if (response.data.bonuses) {
              commit("moduleMain/SET_BONUSES", response?.data.bonuses, {
                root: true,
              });
            } else {
              commit("moduleMain/SET_BONUSES", [], {
                root: true,
              });
            }

            resolve(response);
          } else {
            localStorage.setItem("siteSuspended", true);

            reject(response);
          }
        })
        .catch((error) => {
          localStorage.setItem("siteSuspended", true);
          reject({
            message: error?.data ? error.data.error.message : error.message,
          });
        });
    });
  },

  getDomainSettingTreeSport({ commit }, payload) {
    return new Promise((resolve, reject) => {
      authAxios
        .GetDomainSettingTreeSport(payload)
        .then((response) => {
          //if (response.status == 200) {
          //========= SETTINGS ==========//
          commit("SET_DOMAIN_SETTINGS", response.setting);

          if (response.setting.baseUrl) {
            localStorage.setItem("baseUrl", response.setting?.baseUrl);
            axios.defaults.baseURL = response.setting.baseUrl;
          } else localStorage.removeItem("baseUrl");
            // localStorage.setItem("baseUrl", "https://uni.teslaexch.com");

          localStorage.setItem(
            "domainSetting",
            JSON.stringify(response.setting)
          );

          localStorage.setItem("siteSuspended", response.setting.siteSuspended);

          if (response.bonuses) {
            commit("moduleMain/SET_BONUSES", response?.bonuses, {
              root: true,
            });
          } else {
            commit("moduleMain/SET_BONUSES", [], {
              root: true,
            });
          }

          //======== EVENTS ============//
          commit("moduleMain/SET_EVENTS", response.events, {
            root: true,
          });
          localStorage.setItem(
            "TreeSportWithEvent",
            JSON.stringify(response.events)
          );

            localStorage.setItem(
                "games",
                JSON.stringify(response.games)
            );
          localStorage.setItem("tv", JSON.stringify(response.tv));
          //=============================//
          resolve(response);
          /*} else {
            localStorage.setItem("siteSuspended", true);

            reject(response);
          }*/
        })
        .catch((error) => {
          localStorage.setItem("siteSuspended", true);
          reject({
            message: error?.data ? error.data.error.message : error.message,
          });
        });
    });
  },
};

export default actions;
