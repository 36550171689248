// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

let betSlipInitialData = [];
if (localStorage.getItem("betslips")) {
  betSlipInitialData = JSON.parse(localStorage.getItem("betslips"));
} else {
  betSlipInitialData = [
    { title: "100", value: 100 },
    { title: "500", value: 500 },
    { title: "1k", value: 1000 },
    { title: "2k", value: 2000 },
    { title: "3k", value: 3000 },
    { title: "4k", value: 4000 },
    { title: "5k", value: 5000 },
    { title: "6k", value: 6000 },
  ];
}

const state = {
  BetSlipData: betSlipInitialData,

  domainSettings: {},
};

export default state;
