import * as signalR from "@microsoft/signalr";

let url = "";
if (
  location.hostname.includes("test") ||
  location.host.includes("localhost") ||
  location.hostname.includes("pages.dev")
) {
  url = "https://testhub.teslaexch.com/teslahub";
} else {
  url = "https://hub.teslaexch.com/teslahub";
}

const connection = new signalR.HubConnectionBuilder()
  .withUrl(url)
  .configureLogging(signalR.LogLevel.Information)
  .build();

export default {
  startConnection() {
    connection
      .start()
      .then(RegisterClient)
      .catch((err) => {
        console.error(err.toString());
      });
  },
  getConnection() {
    return connection;
  },
  addReceiveMessageListener(callback) {
    connection.on("Message", (message) => {
      callback(message);
    });
  },
  async disconnect() {
    await connection.stop();
  },
};

function RegisterClient() {
  let token = "";
  if (localStorage.getItem("accessToken")) {
    token = localStorage.getItem("accessToken");
  }

  const registerClientInput = {
    Browser: getBrowserVersion(),
    Domain: window.location.origin,
    CurrentPage: window.location.href,
    Token: token,
  };

  connection
    .invoke("RegisterClient", registerClientInput)

    .then(function () {
      console.log("the client is successfully registered.");
    })
    .catch(function (err) {
      console.log("Error during register client: " + err.toString());
    });
}

function getBrowserVersion() {
  var ua = navigator.userAgent;
  var tem;
  var M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  //console.log( M.join(' '));
  return M.join(" ");
}
